import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';

const Dashboard = React.lazy(() => import('./components/dashboard/Dashboard'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    {/* Suspense envuelve al componente perezoso */}
    <Suspense fallback={<div>Cargando...</div>}>
      <Dashboard />
    </Suspense>
  </Router>
);

